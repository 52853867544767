import axios from "axios";
import { withNamespaces } from "react-i18next";
import $ from "jquery";
import SimpleBar from "simplebar-react";

import customStyles from "../../assets/select/customStyles";
import Select from "react-select";

import SelectType from "../CommonComponents/SelectType";

import moment from "moment";
import { DateTimePicker, Localization } from "react-widgets";
import { DateLocalizer, NumberLocalizer } from "react-widgets/IntlLocalizer";
import "react-widgets/styles.css";
import ReactLoading from "react-loading";

import { getPngData } from "recharts-to-png";
import FileSaver from "file-saver";

import { useReactToPrint } from "react-to-print";

import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts";

import { confirmAlert } from "react-confirm-alert";

import TreeArea from "../CommonComponents/TreeArea.js";

//filter pictures
import pic1 from "../../assets/images/mgsuite/Statistics_32x32.png";
import pic2 from "../../assets/images/mgsuite/Statistics_MaxOccupancyPerDay_32x32.png";
import pic3 from "../../assets/images/mgsuite/Statistics_LoopMovements_32x32.png";
import pic4 from "../../assets/images/mgsuite/message_16x16.png";

import todayIcon from "../../assets/images/mgsuite/fullday.png";
import yesterdayIcon from "../../assets/images/mgsuite/fullday.png";
import thisWeekIcon from "../../assets/images/mgsuite/fullweek.png";
import lastWeekIcon from "../../assets/images/mgsuite/fullweek.png";
import thisMonthIcon from "../../assets/images/mgsuite/fullmonth.png";
import lastMonthIcon from "../../assets/images/mgsuite/fullmonth.png";
import thisYearIcon from "../../assets/images/mgsuite/fullyear.png";
import lastYearIcon from "../../assets/images/mgsuite/fullyear.png";

import printerIcon from "../../assets/images/mgsuite/printer-icon.png";
import csvIcon from "../../assets/images/mgsuite/csv-icon.png";
import xlsxIcon from "../../assets/images/mgsuite/xlsx-icon.png";
import pngIcon from "../../assets/images/mgsuite/png-icon.png";

import lineIcon from "../../assets/images/mgsuite/Line-chart_24x24.png";
import barIcon from "../../assets/images/mgsuite/Bar-chart_24x24.png";
import tableIcon from "../../assets/images/mgsuite/Tabelle_24x24.png";

import settingsIcon from "../../assets/images/mgsuite/settings-icon.png";

import i18next from "i18next";
import CustomDateLabel from "./components/CustomDateLabel";

let areaMultiple;

var dt1 = new Date();
dt1.setHours(0, 0, 0, 0);

var dt2 = new Date();
dt2.setHours(23, 59, 0, 0);

let selectedType = "AreaValues";
let selectedAreas = [];
let settingsopen;

let types = {
  belegung: true,
  maxBelegungProTag: true,
  loopMovements: false,
  heatMap: false,
};

const showSecond = true;
const str = showSecond ? "HH:mm:ss" : "HH:mm";

let pageLoaded = true;

const Statistik = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  let link = process.env.REACT_APP_API_URL;
  let customerTypes = JSON.parse(localStorage.getItem("lotTypes"));

  const [token, setToken] = useState(localStorage.getItem("JWT"));

  const [data, setData] = useState([]);
  // console.log(data);

  const maxNum = (data) => {
    if (data) {
      let maxNum = data.reduce((max, obj) => {
        Object.keys(obj).forEach((key) => {
          if (key !== "name") {
            let num = parseInt(obj[key], 10);
            max = num > max ? num : max;
          }
        });
        return max;
      }, -Infinity);
      return maxNum;
    } else {
      return;
    }
  };

  const [dataCSV, setDataCSV] = useState();

  const [carParkId, setCarParkId] = useState();
  const [areaId, setAreaId] = useState();
  const [areaIds, setAreaIds] = useState([]);
  const [areaName, setAreaName] = useState();
  const [areaNames, setAreaNames] = useState();
  const [selectedT, setSelectedT] = useState([1]);
  const [lineTotal, setLineTotal] = useState(false);
  const [barTotal, setBarTotal] = useState(false);

  const [makeUpdate, setMakeUpdate] = useState(false);
  const [loaderShow, setLoaderShow] = useState(false);

  const [dateTime1, setDateTime1] = useState(dt1);
  const [dateTime2, setDateTime2] = useState(dt2);

  // Modify after rest request

  const [selectedDT1, setselectedDT1] = useState(dateTime1);
  const [selectedDT2, setselectedDT2] = useState(dateTime2);

  const [lng, setLng] = useState(localStorage.getItem("i18nextLng"));

  const settingsContainer = useRef(null);
  const chartsRef = useRef();

  //Custom Date
  const [isCustomDate, setIsCustomDate] = useState(false);
  const selectRef = useRef(null);

  const openSelect = () => {
    selectRef.current.onMenuOpen();
    setIsCustomDate(true);
  };

  // Handle downloads

  const handlePng = React.useCallback(async () => {
    if (chartsRef.current !== undefined) {
      // Send the chart to getPngData
      const pngData = await getPngData(chartsRef.current);
      // Use FileSaver to download the PNG
      FileSaver.saveAs(pngData, "Data.png");
    }
  }, [chartsRef.current]);

  const handlePrint = useReactToPrint({
    content: () => chartsRef.current,
  });

  const handleCSV = () => {
    let requestCSV = link + "/api/Statistic/" + selectedType + "/CSV";

    const data = {
      areaIds: areaIds,
      beginn: moment(dateTime1).toISOString(true).split("+")[0] + "Z",
      end: moment(dateTime2).toISOString(true).split("+")[0] + "Z",
      lotTypes: selectedT,
    };

    axios
      .post(
        requestCSV,
        data,
        { responseType: "blob" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        FileSaver.saveAs(response.data, "Data.csv");
      });
  };

  const handleExcel = () => {
    let requestCSV = link + "/api/Statistic/" + selectedType + "/xlsx";

    const data = {
      areaIds: areaIds,
      beginn: moment(dateTime1).toISOString(true).split("+")[0] + "Z",
      end: moment(dateTime2).toISOString(true).split("+")[0] + "Z",
      lotTypes: selectedT,
    };

    axios
      .post(
        requestCSV,
        data,
        { responseType: "blob" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        FileSaver.saveAs(response.data, "Data.xlsx");
      });
  };

  // Close settings if clicked outside

  $(document).on("click", function (e) {
    if (
      settingsopen == true &&
      settingsContainer.current &&
      !settingsContainer.current.contains(e.target) &&
      e.target.id != "statistik-sicon" &&
      !$(e.target).is(".settings-item") &&
      !$(e.target).children().hasClass("settings-item") &&
      !$(e.target).hasClass("select-icon") &&
      !$(e.target).parents(".rc-time-picker-panel").length == 1 &&
      !$(e.target).parent().hasClass("css-ykwh22") &&
      !$(e.target).parent().hasClass("css-6q0nyr-Svg") &&
      !$(e.target).hasClass("css-ykwh22") &&
      $(e.target)[0] !== $("body")[0] &&
      $(e.target).parents(".MuiPaper-root").length !== 1 &&
      $(e.target).parents(".MuiPopover-root").length !== 1 &&
      !$(e.target).attr("aria-hidden")
    ) {
      $("#settings-statistics").hide();
      settingsopen = false;
    }
  });

  // Options for select
  const optionData = {
    today: {
      translationKey: "allgemein.zeitraumfilter.heute",
      icon: todayIcon,
    },
    yesterday: {
      translationKey: "allgemein.zeitraumfilter.gestern",
      icon: yesterdayIcon,
    },
    thisweek: {
      translationKey: "allgemein.zeitraumfilter.dieseWoche",
      icon: thisWeekIcon,
    },
    lastweek: {
      translationKey: "allgemein.zeitraumfilter.letzteWoche",
      icon: lastWeekIcon,
    },
    thismonth: {
      translationKey: "allgemein.zeitraumfilter.diesenMonat",
      icon: thisMonthIcon,
    },
    lastmonth: {
      translationKey: "allgemein.zeitraumfilter.letztenMonat",
      icon: lastMonthIcon,
    },
    thisyear: {
      translationKey: "allgemein.zeitraumfilter.diesesJahr",
      icon: thisYearIcon,
    },
    lastyear: {
      translationKey: "allgemein.zeitraumfilter.letztesJahr",
      icon: lastYearIcon,
    },
  };

  const createOptionLabel = (value) => {
    const { translationKey, icon } = optionData[value];
    return (
      <div>
        <img src={icon} className="select-icon" />
        {props.t(translationKey)}
      </div>
    );
  };

  const createOptionsTime = () => [
    { value: "today", label: createOptionLabel("today") },
    { value: "yesterday", label: createOptionLabel("yesterday") },
    { value: "thisweek", label: createOptionLabel("thisweek") },
    { value: "lastweek", label: createOptionLabel("lastweek") },
    { value: "thismonth", label: createOptionLabel("thismonth") },
    { value: "lastmonth", label: createOptionLabel("lastmonth") },
    { value: "thisyear", label: createOptionLabel("thisyear") },
    { value: "lastyear", label: createOptionLabel("lastyear") },
  ];

  const [optionsTime, setOptionsTime] = useState(createOptionsTime());

  useEffect(() => {
    setOptionsTime(createOptionsTime());
  }, [currentLanguage]);

  const optionsCharts = [
    {
      value: "line",
      label: (
        <div className="flex-iac settings-item">
          <img src={lineIcon} className="select-icon" />{" "}
          {props.t("statistics.diagrammTypen_.linie")}
        </div>
      ),
    },
    {
      value: "bar",
      label: (
        <div className="flex-iac settings-item">
          <img src={barIcon} className="select-icon" />{" "}
          {props.t("statistics.diagrammTypen_.balken")}
        </div>
      ),
    },
    // { value: 'table', label: <div className="flex-iac settings-item"><img src={tableIcon} className="select-icon"/> {props.t('statistics.diagrammTypen_.tabelle')}</div> },
  ];

  const optionsCharts2 = [
    {
      value: "bar",
      label: (
        <div className="flex-iac settings-item">
          <img src={barIcon} className="select-icon" />{" "}
          {props.t("statistics.diagrammTypen_.balken")}
        </div>
      ),
    },
    // { value: 'table', label: <div className="flex-iac settings-item"><img src={tableIcon} className="select-icon"/> {props.t('statistics.diagrammTypen_.tabelle')}</div> },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: "today",
    label: createOptionLabel("today"),
  });

  const [selectedOption2, setSelectedOption2] = useState({
    value: "line",
    label: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <img src={lineIcon} className="select-icon" />{" "}
        {props.t("statistics.diagrammTypen_.linie")}
      </div>
    ),
  });

  useEffect(() => {
    i18next.on("languageChanged", (lng) => {
      setCurrentLanguage(lng);
    });

    return () => {
      i18next.off("languageChanged");
    };
  }, []);

  useEffect(() => {
    setSelectedOption({
      value: selectedOption.value,
      label: createOptionLabel(selectedOption.value),
    });
  }, [currentLanguage, selectedOption.value]);

  useEffect(() => {
    selectedType = "AreaValues";
    areaMultiple = true;

    dt1 = new Date();
    dt1.setHours(0, 0, 0, 0);
    setDateTime1(dt1);
    dt2 = new Date();
    dt2.setHours(23, 59, 0, 0);
    setDateTime2(dt2);

    $("#" + selectedType).css("background-color", "#e9ecef");
  }, []);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + localStorage.getItem("JWT");
    setToken(localStorage.getItem("JWT"));
  }, [localStorage.getItem("JWT")]);

  function updateData(data) {
    setData(data);
    return;
  }

  // Render chart

  function chart() {
    let showLineChart;
    let name;

    let yLabel;

    let selectedDT1c = moment
      .utc(data.firstValueDate)
      .format("DD.MM.YYYY, HH:mm");
    let selectedDT2c = moment
      .utc(data.lastValueDate)
      .format("DD.MM.YYYY, HH:mm");

    let selectedLots = areaNames ?? "";
    let aggregateWindow =
      data.aggregateWindow != null ? " ( " + data.aggregateWindow + " )" : "";

    if (selectedOption2.value == "line") {
      showLineChart = true;
    } else {
      showLineChart = false;
    }

    if (selectedType == "AreaValues") {
      name = props.t("statistics.belegung");
      yLabel = props.t("statistics.stellplaetze");
    } else if (selectedType == "MaxOccupancyPerDay") {
      name = props.t("statistics.maxBelegungProTag");
      yLabel = props.t("elementeAllgemein.areaWerte.RealOccupied");
    } else if (selectedType == "loopMovements") {
      name = props.t("statistics.loopmovements.loopmovements");
      yLabel = props.t("statistics.loopmovements.anzahlDerEinUndAusfahrten");
    }

    let interval = setInterval(updateText, 1);
    function updateText() {
      if ($("#chartContainer").find(".recharts-wrapper").length > 0) {
        $("#chartContainer").find("#chartName").remove();
        $("#chartContainer")
          .find(".recharts-wrapper")
          .append(
            '<div id="chartName">' +
              name +
              " : " +
              selectedLots +
              "<br/>" +
              selectedDT1c +
              " - " +
              selectedDT2c +
              aggregateWindow +
              "</div>"
          );
        $("#chartContainer").find("#chartName").addClass("chart-name");
        clearInterval(interval);
      }
    }

    function barChart() {
      function renderBarsOccupied(type) {
        let nameOccupied = [];
        let colorOccupied = [];
        let data;

        if (type == "T") {
          data = selectedT;

          if (selectedT.includes(0)) {
            nameOccupied.push("");
            colorOccupied.push("");
          }

          if (customerTypes) {
            for (let i = 0; i < customerTypes.length; i++) {
              if (selectedT.includes(customerTypes[i].id)) {
                nameOccupied.push(customerTypes[i].name);

                let r = customerTypes[i].colorFree.r;
                let g = customerTypes[i].colorFree.g;
                let b = customerTypes[i].colorFree.b;

                let color = "rgb(" + r + "," + g + "," + b + ")";

                colorOccupied.push(color);
              }
            }
          }
        }

        const bars = data.map((val, i) => {
          if (val == 0) {
            return;
          } else {
            return (
              <Bar
                name={nameOccupied[i] + " (" + type + ")"}
                dataKey={"o-" + type + "-" + val}
                fill={colorOccupied[i]}
                dot={false}
              />
            );
          }
        });

        return bars;
      }

      return (
        <BarChart
          data={data.data}
          ref={chartsRef}
          margin={{ top: 50, right: 25, left: 0, bottom: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tickFormatter={(tickItem) =>
              moment(tickItem).utc().format("DD.MM.YYYY HH:mm")
            }
            tick={{ fontSize: 12, width: "20px", wordWrap: "break-word" }}
            interval="preserveStartEnd"
            label={{
              value: props.t("statistics.zeit"),
              angle: 0,
              offset: -20,
              position: "insideBottom",
            }}
          ></XAxis>
          <YAxis
            type="number"
            domain={[0, maxNum(data.data) + 20]}
            label={{
              value: yLabel,
              angle: -90,
              offset: 10,
              position: "insideLeft",
            }}
          />
          <Tooltip
            cursor={false}
            isAnimationActive={false}
            labelFormatter={(label) =>
              moment(label).utc().format("DD.MM.YYYY HH:mm")
            }
          />
          {selectedT.includes(0) ? (
            <Bar
              name={props.t("allgemein.gesamt") + " (G,T)"}
              dataKey={"o-0"}
              fill={"#000000"}
              dot={false}
            />
          ) : (
            <></>
          )}
          {selectedT.length > 0 && renderBarsOccupied("T")}
        </BarChart>
      );
    }

    function lineChart() {
      const CustomTooltip = ({ active, payload, label }) => {
        if (payload && active && payload.length) {
          let data = [];

          for (let i = 0; i < payload.length; i++) {
            let dataKey = `${payload[i].dataKey}`;
            let type = dataKey.charAt(0);
            let string;
            let name;
            let value;
            let total;
            let color;

            if (type == "o") {
              value = `${payload[i].value}`;
              name = `${payload[i].name}`;
              color = `${payload[i].color}`;

              string = "c-" + dataKey.substring(2, dataKey.length);
              let p = payload[i].payload;

              for (var key of Object.keys(p)) {
                if (key == string) {
                  total = p[key];
                }
              }

              data.push(
                <li className="recharts-item" style={{ color: color }}>
                  {name} : {value} / {total}
                </li>
              );
            }
          }
          label = moment(label).utc().format("DD.MM.YYYY HH:mm");

          if (active && payload && payload.length) {
            return (
              <div className="recharts-tooltip">
                <p
                  style={{ margin: "0px", textAlign: "center" }}
                >{`${label}`}</p>
                {data.length > 0 && (
                  <ul style={{ padding: "0px", margin: "0px" }}>{data}</ul>
                )}
              </div>
            );
          }

          return null;
        } else {
          return null;
        }
      };

      function renderLinesConstructed(type) {
        let colorConstructed = [];

        let data;

        if (type == "T") {
          data = selectedT;

          if (selectedT.includes(0)) {
            colorConstructed.push("");
          }

          if (customerTypes) {
            for (let i = 0; i < customerTypes.length; i++) {
              if (selectedT.includes(customerTypes[i].id)) {
                let r = customerTypes[i].colorFree.r;
                let g = customerTypes[i].colorFree.g;
                let b = customerTypes[i].colorFree.b;

                let color = "rgb(" + r + "," + g + "," + b + ")";

                colorConstructed.push(color);
              }
            }
          }
        }

        const lines = data.map((val, i) => {
          if (val == 0) {
            return;
          } else {
            return (
              <Line
                name={props.t("allgemein.gesamt") + " (" + type + ")"}
                type="monotone"
                dataKey={"c-" + type + "-" + val}
                stroke={colorConstructed[i]}
                strokeWidth={1}
                dot={false}
              />
            );
          }
        });

        return lines;
      }

      function renderLinesOccupied(type) {
        let nameOccupied = [];
        let colorOccupied = [];
        let data;

        if (type == "T") {
          data = selectedT;

          if (selectedT.includes(0)) {
            nameOccupied.push("");
            colorOccupied.push("");
          }

          if (customerTypes) {
            for (let i = 0; i < customerTypes.length; i++) {
              if (selectedT.includes(customerTypes[i].id)) {
                nameOccupied.push(customerTypes[i].name);

                let r = customerTypes[i].colorFree.r;
                let g = customerTypes[i].colorFree.g;
                let b = customerTypes[i].colorFree.b;

                let color = "rgb(" + r + "," + g + "," + b + ")";

                colorOccupied.push(color);
              }
            }
          }
        }

        const lines = data.map((val, i) => {
          if (val == 0) {
            return;
          } else {
            console.debug(
              "Rendering Occupied Lines. Type: %s; DataKey: " +
                "o-" +
                type +
                "-" +
                val +
                " Data: %o",
              type,
              data
            );
            return (
              <Line
                name={nameOccupied[i] + " (" + type + ")"}
                type="monotone"
                dataKey={"o-" + type + "-" + val}
                stroke={colorOccupied[i]}
                strokeWidth={2}
                dot={false}
              />
            );
          }
        });

        return lines;
      }

      console.debug("Rendering Line Chart %o", data);

      return (
        <LineChart
          data={data.data}
          ref={chartsRef}
          margin={{ top: 50, right: 25, left: 0, bottom: 30 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickFormatter={(tickItem) =>
              moment(tickItem).utc().format("DD.MM.YYYY HH:mm")
            }
            dataKey="name"
            tick={{ fontSize: 12, width: "20px", wordWrap: "break-word" }}
            interval="preserveStart"
            label={{
              value: props.t("statistics.zeit"),
              angle: 0,
              offset: -20,
              position: "insideBottom",
            }}
          ></XAxis>
          <YAxis
            tick={{ fontSize: 12 }}
            domain={[0, maxNum(data.data) + 20]}
            label={{
              value: yLabel,
              angle: -90,
              offset: 10,
              position: "insideLeft",
            }}
          />
          {selectedType == "AreaValues" && (
            <Tooltip
              cursor={false}
              isAnimationActive={false}
              content={<CustomTooltip />}
            />
          )}
          {selectedType == "MaxOccupancyPerDay" && (
            <Tooltip
              cursor={false}
              isAnimationActive={false}
              content={<CustomTooltip />}
            />
          )}
          {selectedT.includes(0) ? (
            <Line
              name={props.t("allgemein.gesamt") + " (G, T)"}
              type="monotone"
              dataKey={"o-0"}
              stroke={"#000000"}
              strokeWidth={2}
              dot={false}
            />
          ) : (
            <></>
          )}
          {selectedT.includes(0) ? (
            <Line
              name={props.t("allgemein.gesamt") + " (G, T)"}
              type="monotone"
              dataKey={"c-0"}
              stroke={"#000000"}
              strokeWidth={2}
              dot={false}
            />
          ) : (
            <></>
          )}
          {selectedT.length > 0 && renderLinesConstructed("T")}
          {selectedT.length > 0 && renderLinesOccupied("T")}
        </LineChart>
      );
    }

    function showChart() {
      if (showLineChart == true) {
        return lineChart();
      } else {
        return barChart();
      }
    }

    function settingsOpen() {
      $("#settings-statistics").show();
      settingsopen = true;
    }
    return (
      <div
        id="chart"
        className="left-container-se"
        style={{ backgroundColor: "white" }}
      >
        <div>
          <img
            src={settingsIcon}
            title="settings"
            id="statistik-sicon"
            onClick={() => settingsOpen()}
          />
        </div>

        <ResponsiveContainer height="100%" width="100%" id="chartContainer">
          {showChart()}
        </ResponsiveContainer>
      </div>
    );
  }

  // Select chart type

  function selectType(name) {
    if (selectedType == name) {
      return;
    } else {
      setAreaId();
      setAreaIds();
      setAreaName();
      setAreaNames();
      setSelectedT([1]);
      setData([]);
      selectedType = name;
      selectedAreas = [];
      $(".park-container").find("input").prop("checked", false);
      $(".typ-container-se")
        .css("background-color", "unset")
        .css("color", "unset");
      $("#" + name)
        .css("background-color", "#e9ecef")
        .css("color", "unset");

      if (name == "AreaValues") {
        areaMultiple = true;
        setSelectedOption2({
          value: "line",
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={lineIcon} className="select-icon" />
              {props.t("statistics.diagrammTypen_.linie")}
            </div>
          ),
        });
      } else {
        areaMultiple = true;
      }

      if (name == "loopMovements") {
        setSelectedOption2({
          value: "bar",
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={barIcon} className="select-icon" />
              {props.t("statistics.diagrammTypen_.balken")}
            </div>
          ),
        });
      } else if (name == "MaxOccupancyPerDay") {
        setSelectedOption2({
          value: "line",
          label: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img src={lineIcon} className="select-icon" />
              {props.t("statistics.diagrammTypen_.linie")}
            </div>
          ),
        });
      } else {
        setMakeUpdate((current) => !current);
      }
    }
  }

  // Select date and time

  function dateTimeChange1(props) {
    setDateTime1(props);
    setIsCustomDate(true);
  }

  function dateTimeChange2(props) {
    setDateTime2(props);
    setIsCustomDate(true);
  }

  function setTime(selected) {
    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });

    let date1;
    let date2;

    switch (selected.value) {
      case "today":
        date1 = moment();
        date2 = moment();
        break;
      case "yesterday":
        date1 = moment().subtract(1, "days");
        date2 = moment().subtract(1, "days");
        break;
      case "thisweek":
        date1 = moment().startOf("week");
        date2 = moment();
        break;
      case "lastweek":
        date1 = moment().subtract(1, "weeks").startOf("week");
        date2 = moment().subtract(1, "weeks").endOf("week");
        break;
      case "thismonth":
        date1 = moment().startOf("month");
        date2 = moment();
        break;
      case "lastmonth":
        date1 = moment().subtract(1, "months").startOf("month");
        date2 = moment().subtract(1, "months").endOf("month");
        break;
      case "thisyear":
        date1 = moment().startOf("year");
        date2 = moment();
        break;
      case "lastyear":
        date1 = moment().subtract(1, "years").startOf("year");
        date2 = moment().subtract(1, "years").endOf("year");
        break;
    }

    dt1 = new Date(date1);
    dt1.setHours(0, 0, 0, 0);
    dt2 = new Date(date2);
    dt2.setHours(23, 59, 0, 0);

    setDateTime1(dt1);
    setDateTime2(dt2);
  }

  function changeTime(props) {
    setSelectedOption(props);
    setTime(props);
  }

  function changeChart(props) {
    setSelectedOption2(props);
  }

  // Get ID from area tree

  function handleTreeArea(props) {
    setAreaId(props.areaId);
    setAreaIds(props.areaIds);
    setAreaName(props.areaName);
    setAreaNames(props.areaNames);
    setCarParkId(props.carParkId);
  }

  // Get data for statistics

  function handleRequest() {
    let statTypeLink = selectedType;
    if (statTypeLink == "AreaValues") {
      statTypeLink = "AreaValuesReChart";
    } else if (statTypeLink == "MaxOccupancyPerDay") {
      statTypeLink = "MaxOccupancyPerDayReChart";
    }

    if (carParkId === undefined || areaId === undefined) {
      setLoaderShow(false);
      confirmAlert({
        message: props.t("statistics.alerts.noAreaSelected"),
        buttons: [
          {
            label: "OK",
            onClick: () => {},
          },
        ],
      });
    } else {
      setLoaderShow(true);

      const data = {
        areaIds: areaIds,
        beginn: moment(dateTime1).toISOString(true).split("+")[0] + "Z",
        end: moment(dateTime2).toISOString(true).split("+")[0] + "Z",
        lotTypes: [],
      };

      let request = link + "/api/Statistic/" + statTypeLink;

      // console.log({reqTest: request})

      axios
        .post(request, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setselectedDT1(dateTime1);
          setselectedDT2(dateTime2);
          // console.log({newStartDate: dateTime1})
          // console.log({newEndDate: dateTime2})

          setLoaderShow(false);

          updateData(response.data);
        })

        .catch((error) => {
          // console.log(error)
        });
    }
  }

  // Settings on the right side

  function settings() {
    function selectedTypes() {
      return (
        <div className="content-group">
          <div className="content-group-name">{props.t("allgemein.typ")}</div>

          {types.belegung == true && (
            <div
              className="typ-container-se"
              onClick={() => selectType("AreaValues")}
              id="AreaValues"
            >
              <img src={pic1} className="typ-img-se" />
              {props.t("statistics.belegung")}
            </div>
          )}

          {types.maxBelegungProTag == true && (
            <div
              className="typ-container-se"
              onClick={() => selectType("MaxOccupancyPerDay")}
              id="MaxOccupancyPerDay"
            >
              <img src={pic2} className="typ-img-se" />
              {props.t("statistics.maxBelegungProTag")}
            </div>
          )}

          {types.loopMovements == true && (
            <div
              className="typ-container-se"
              onClick={() => selectType("loopMovements")}
              id="loopMovements"
            >
              <img src={pic3} className="typ-img-se" />
              {props.t("statistics.loopmovements.loopmovements")}
            </div>
          )}

          {types.heatMap == true && (
            <div
              className="typ-container-se"
              onClick={() => selectType("heatMap")}
              id="heatMap"
            >
              <img src={pic2} className="typ-img-se" />
              Heatmap
            </div>
          )}
        </div>
      );
    }

    function lotTypes() {
      function changeTypeGroup(val, type) {
        if (type == "Type") {
          setSelectedT(val);
        }
      }

      if (
        selectedType == "AreaValues" ||
        selectedType == "MaxOccupancyPerDay"
      ) {
        return (
          <div className="content-group">
            <div className="content-group-name">
              {props.t("elementeAllgemein.typ")}
            </div>
            <SelectType
              type={"Statistics"}
              onChange={(val) => changeTypeGroup(val, "Type")}
            ></SelectType>
          </div>
        );
      }
    }

    function chartOptions() {
      let options;
      if (selectedType == "loopMovements") {
        options = optionsCharts2;
      } else {
        options = optionsCharts;
      }

      //functions to display JSX
      const renderSelectInput = () => {
        return (
          <>
            <div className={isCustomDate ? "select-date-input" : ""}>
              <Select
                ref={selectRef}
                styles={customStyles}
                isSearchable={false}
                value={selectedOption}
                onChange={changeTime}
                options={optionsTime}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 3,
                  colors: {
                    ...theme.colors,
                    primary: "#20a0ff",
                  },
                })}
              />
            </div>
            {isCustomDate && (
              <CustomDateLabel
                hidden={!isCustomDate}
                openSelect={openSelect}
                showInput={setIsCustomDate}
              />
            )}
          </>
        );
      };

      return (
        <div className="content-group" style={{ marginTop: "15px" }}>
          <div className="content-group-name">
            {props.t("statistics.panelHeaderOptionen")}
          </div>
          <Select
            styles={customStyles}
            isSearchable={false}
            defaultValue={selectedOption2}
            onChange={changeChart}
            options={options}
            theme={(theme) => ({
              ...theme,
              borderRadius: 3,
              colors: { ...theme.colors, primary: "#20a0ff" },
            })}
          />
        </div>
      );
    }

    return (
      <div
        id="settings-statistics"
        className="right-container-se"
        ref={settingsContainer}
      >
        <div>
          <div className="content-name">
            {props.t("allgemein.einstellungen")}
          </div>
          <SimpleBar className="simplebar-se">
            <div className="content">
              {selectedTypes()}
              <div className="content-group">
                <div className="content-group-name">
                  {props.t("allgemein.zeitraum")}
                </div>
                <div className={isCustomDate ? "select-date-input" : ""}>
                  <Select
                    ref={selectRef}
                    styles={customStyles}
                    isSearchable={false}
                    value={selectedOption}
                    onChange={changeTime}
                    options={optionsTime}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 3,
                      colors: { ...theme.colors, primary: "#20a0ff" },
                    })}
                  />
                </div>
                {isCustomDate && (
                  <CustomDateLabel
                    hidden={!isCustomDate}
                    openSelect={openSelect}
                    showInput={setIsCustomDate}
                  />
                )}
                <div
                  style={{ width: "100%", flexDirection: "column" }}
                  className="flex-sb"
                >
                  <Localization
                    date={
                      new DateLocalizer({ culture: "de-DE", firstOfWeek: 1 })
                    }
                    number={new NumberLocalizer({ culture: "de-DE" })}
                  >
                    <div
                      style={{
                        textAlign: "left",
                        padding: "5px",
                        width: "100%",
                        marginTop: "15px",
                      }}
                    >
                      {props.t("allgemein.von")}
                    </div>
                    <DateTimePicker
                      includeTime
                      parse={(str) =>
                        moment(str, "DD/MM/YYYY HH:mm").toDate() <= new Date()
                          ? moment(str, "DD/MM/YYYY HH:mm").toDate()
                          : new Date()
                      }
                      style={{ width: "100%" }}
                      className="datetime-picker"
                      value={dateTime1}
                      max={new Date()}
                      time={true}
                      onChange={dateTimeChange1}
                    />
                    <div
                      style={{
                        textAlign: "left",
                        padding: "5px",
                        width: "100%",
                      }}
                    >
                      {props.t("allgemein.bis")}
                    </div>
                    <DateTimePicker
                      includeTime
                      parse={(str) =>
                        moment(str, "DD/MM/YYYY HH:mm").toDate() <= new Date()
                          ? moment(str, "DD/MM/YYYY HH:mm").toDate()
                          : new Date()
                      }
                      style={{ width: "100%" }}
                      className="datetime-picker"
                      value={dateTime2}
                      min={dateTime1}
                      max={new Date()}
                      time={true}
                      onChange={dateTimeChange2}
                    />
                  </Localization>
                </div>
              </div>

              {selectedType == "AreaValues" && lotTypes()}
              {selectedType == "MaxOccupancyPerDay" && chartOptions()}
              {selectedType == "MaxOccupancyPerDay" && lotTypes()}

              {/* {selectedType=="loopMovements" && (chartOptions() )} */}

              <div className="content-group">
                <div className="content-group-name">
                  {props.t("statistics.diagrammExport")}
                </div>
                <div className="flex-iac">
                  <img
                    onClick={handlePng}
                    className="export-img"
                    title="png"
                    src={pngIcon}
                  ></img>

                  <img
                    src={csvIcon}
                    onClick={handleCSV}
                    className="export-img"
                    title="csv"
                    style={{ marginLeft: "20px" }}
                  ></img>
                  <img
                    src={xlsxIcon}
                    onClick={handleExcel}
                    className="export-img"
                    title="xlsx"
                    style={{ marginLeft: "20px" }}
                  ></img>

                  <img
                    onClick={handlePrint}
                    className="export-img"
                    title="print"
                    style={{ marginLeft: "20px" }}
                    src={printerIcon}
                  ></img>
                </div>
              </div>

              {selectedType != "heatMap" && (
                <div className="content-group">
                  <div className="content-group-name">
                    {props.t("elementeAllgemein.area")}
                  </div>
                  <div className="content-areas-se" style={{ height: "200px" }}>
                    <SimpleBar style={{ height: "200px" }}>
                      <TreeArea
                        multi={areaMultiple}
                        page={"statistics"}
                        type={selectedType}
                        onChange={(props) => handleTreeArea(props)}
                      ></TreeArea>
                    </SimpleBar>
                  </div>
                  <button
                    className="button"
                    style={{ marginTop: "10px", marginBottom: "10px" }}
                    onClick={handleRequest}
                  >
                    {props.t("allgemein.senden")}
                  </button>
                </div>
              )}
            </div>
          </SimpleBar>
        </div>
      </div>
    );
  }

  return (
    <div className="pagewrapper-2">
      {loaderShow == true && (
        <div className="loader-mini">
          <ReactLoading
            type={"bars"}
            color={"#2a3042"}
            height={80}
            width={80}
            name={"Loading"}
          />
        </div>
      )}
      {selectedType != "heatMap" && chart()}
      {/* {selectedType=="heatMap" && (
       tree()      
        )} */}
      {/* {selectedType=="heatMap" && (
       svgShow()   
        )} */}
      {settings()}
    </div>
  );
};

export default withNamespaces()(Statistik);
