import React, { useState } from "react";
import { Alert } from "reactstrap";
import axios from "axios";
import { withNamespaces } from "react-i18next";
import user from "../../assets/images/users/profile.png";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { confirmAlert } from "react-confirm-alert";
import SimpleBar from "simplebar-react";

const Profile = (props) => {
  let link = localStorage.getItem("link");

  const [userName, setUserName] = useState(localStorage.getItem("userName"));
  const [changePasswordFailShow, setChangePasswordFailShow] = useState(false);
  const [changeEmailFailShow, setChangeEmailFailShow] = useState(false);
  const [failShow, setFailShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [newPasswordConfirm, setNewPasswordConfirm] = useState();
  const [newEmail, setNewEmail] = useState();

  function oldPasswordChange(props) {
    setOldPassword(props);
    setChangePasswordFailShow(false);
  }

  function newPasswordChange(props) {
    setNewPassword(props);
    setChangePasswordFailShow(false);
  }
  function newPasswordConfirmChange(props) {
    setNewPasswordConfirm(props);
    setFailShow(false);
  }
  function newEmailChange(props) {
    setNewEmail(props);
    setChangeEmailFailShow(false);
  }

  function handleValidPWSubmit(e, v) {
    if (newPassword != newPasswordConfirm) {
      setErrorMessage(
        props.t("administration.benutzer.fehlerPasswoerterStimmenNicht")
      );
      setFailShow(true);
      return;
    }

    const data = {
      username: userName,
      oldPassword: oldPassword,
      newPassword: newPassword,
    };

    axios
      .post(link + "/api/User/change-password", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status == "200") {
          confirmAlert({
            message: props.t("profile.confirmPasswordChanged"),
            buttons: [
              {
                label: "OK",
              },
            ],
          });
        }
      })
      .catch((error) => {
        if (error.response.status == "400") {
          setErrorMessage(props.t("login.fehlerPasswortAenderung"));
        } else if (error.response.status == "500")
          setErrorMessage(props.t("login.fehlerPasswortAenderung_2"));

        setChangePasswordFailShow(true);
      });
  }

  function handleValidEmailSubmit(e, v) {
    const data = {
      eMail: newEmail,
    };

    axios
      .post(link + "/api/User/update-email", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status == "200") {
          confirmAlert({
            message: props.t("profile.confirmEmailChanged"),
            buttons: [
              {
                label: "OK",
              },
            ],
          });
        }
      })
      .catch((error) => {});
  }
  function handleDeleteAccountSubmit(e, v) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <h2>{props.t("profile.deleteAccountButton")}</h2>
            <p style={{ whiteSpace: "pre-line" }}>
              {props.t("profile.deleteAccountWarningContent")}
            </p>
            <div style={{ float: "right" }}>
              <button
                className="btn btn-light waves-effect waves-light"
                style={{ marginRight: "10px" }}
                onClick={onClose}
              >
                {props.t("profile.deleteAccountWarningCancelButton")}
              </button>
              <button
                className="btn btn-danger waves-effect waves-light"
                onClick={() => {
                  accountDeletionAPI();
                  onClose();
                }}
              >
                {props.t("profile.deleteAccountWarningConfirmButton")}
              </button>
            </div>
          </div>
        );
      },
    });
  }

  function accountDeletionAPI() {
    const data = {
      username: userName,
    };

    axios
      .post(link + "/api/User/delete", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch((error) => {});
  }

  function changePasswordForm() {
    return (
      <div>
        <div style={{ width: "300px", marginLeft: "20px" }}>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidPWSubmit(e, v);
            }}
            style={{ opacity: "1" }}
          >
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="form-group" style={{ marginTop: "25px" }}>
              <AvField
                autoComplete="new-password"
                name="passwordOld"
                label={props.t("profile.labelPasswortAlt")}
                value={oldPassword}
                type="password"
                required
                placeholder={props.t("profile.labelPasswortAlt_description")}
                onChange={(e) => oldPasswordChange(e.target.value)}
              />
            </div>

            <div className="form-group" style={{ marginTop: "25px" }}>
              <AvField
                autoComplete="new-password"
                name="passwordNew"
                label={props.t("profile.labelPasswortNeu")}
                value={newPassword}
                type="password"
                required
                placeholder={props.t("profile.labelPasswortNeu_description")}
                onChange={(e) => newPasswordChange(e.target.value)}
              />
            </div>

            <div className="form-group" style={{ marginTop: "25px" }}>
              <AvField
                autoComplete="new-password"
                name="passwordNewConfirm"
                label={props.t("profile.labelPasswortConfirm")}
                value={newPasswordConfirm}
                type="password"
                required
                placeholder={props.t(
                  "profile.labelPasswortConfirm_description"
                )}
                onChange={(e) => newPasswordConfirmChange(e.target.value)}
              />
            </div>

            {(changePasswordFailShow == true || failShow == true) && (
              <div className="login-fail">{errorMessage}</div>
            )}

            <div className="mt-3 form-group">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
              >
                {props.t("login.passwortAendern")}
              </button>
            </div>
          </AvForm>
        </div>
      </div>
    );
  }

  function changeEmailForm() {
    return (
      <div>
        <div style={{ width: "300px", marginLeft: "20px" }}>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleValidEmailSubmit(e, v);
            }}
            style={{ opacity: "1" }}
          >
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="form-group" style={{ marginTop: "25px" }}>
              <AvField
                autoComplete="new-email"
                name="emailNew"
                label={props.t("profile.labelEmailNeu")}
                value={newEmail}
                type="email"
                required
                placeholder={props.t("profile.labelEmailNeu_description")}
                onChange={(e) => newEmailChange(e.target.value)}
              />
            </div>

            {changeEmailFailShow == true && (
              <div className="login-fail">{errorMessage}</div>
            )}

            <div className="mt-3 form-group">
              <button
                className="btn btn-primary btn-block waves-effect waves-light"
                type="submit"
              >
                {props.t("profile.emailAendern")}
              </button>
            </div>
          </AvForm>
        </div>
      </div>
    );
  }

  function deleteAccountForm() {
    return (
      <div>
        <div style={{ width: "300px", marginLeft: "20px" }}>
          <AvForm
            className="form-horizontal"
            onValidSubmit={(e, v) => {
              handleDeleteAccountSubmit(e, v);
            }}
            style={{ opacity: "1" }}
          >
            {props.error && props.error ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="mt-3 form-group">
              <button
                className="btn btn-danger btn-block waves-effect waves-light"
                style={{ marginTop: "80px" }}
                type="submit"
              >
                {props.t("profile.deleteAccountButton")}
              </button>
            </div>
          </AvForm>
        </div>
      </div>
    );
  }
  return (
    <div className="pagewrapper-2">
      <div className="left-container-se left-part-defects">
        <SimpleBar style={{ width: "100%", height: "100%" }}>
          <div className="flex-iac" style={{ padding: "20px" }}>
            <img
              src={user}
              alt=""
              className="avatar-md rounded-circle img-thumbnail"
              style={{ width: "60px", height: "60px" }}
            />
            <div style={{ fontSize: "20px", marginLeft: "10px" }}>
              {userName}
            </div>
          </div>

          {changePasswordForm()}
          {changeEmailForm()}
          {deleteAccountForm()}
        </SimpleBar>
      </div>
    </div>
  );
};

export default withNamespaces()(Profile);
