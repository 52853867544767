import axios from "axios";
import { withNamespaces } from "react-i18next";
import SimpleBar from "simplebar-react";

import ReactLoading from "react-loading";

import CsvDownloader from "react-csv-downloader";
import { useReactToPrint } from "react-to-print";

import printerIcon from "../../assets/images/mgsuite/printer-icon.png";
import csvIcon from "../../assets/images/mgsuite/csv-icon.png";

import React, { useState, useEffect, useRef } from "react";

const UsersActivity = (props) => {
  let jwt = localStorage.getItem("JWT");
  let link = localStorage.getItem("link");

  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

  const [loaderShow, setLoaderShow] = useState(false);
  const [data, setData] = useState([]);
  const uaRef = useRef();

  // Get all user activity items

  useEffect(() => {
    axios.get(link + "/api/UserActivityLog/").then((response) => {
      setData(response.data);
      setLoaderShow(false);
    });
  }, []);

  // Convert date to german date format

  function convertDate(date) {
    if (date == null || date === undefined) {
      return props.t("allgemein.unbekannt");
    }

    const currentDate = new Date(date);
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let minutes = currentDate.getMinutes();
    let hours = currentDate.getHours();
    let seconds = currentDate.getSeconds();
    let month;
    let day;

    if (currentDayOfMonth < 10) {
      day = "0" + currentDayOfMonth;
    } else {
      day = currentDayOfMonth;
    }

    if (currentMonth < 10) {
      month = "0" + currentMonth;
    } else {
      month = currentMonth;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    const dateTimeString =
      day +
      "." +
      month +
      "." +
      currentYear +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return dateTimeString;
  }

  const handlePrint = useReactToPrint({
    content: () => uaRef.current,
  });

  // List of all user activity items

  function allActivities() {
    return (
      <div className="left-container-se left-part-lastActivity">
        <div
          className="flex-iac"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <CsvDownloader filename="usersActivity" separator=";" datas={data}>
            <img
              src={csvIcon}
              className="export-img"
              title="csv"
              style={{ marginLeft: "10px" }}
            ></img>
          </CsvDownloader>
          <img
            onClick={handlePrint}
            className="export-img"
            title="print"
            style={{ marginLeft: "10px" }}
            src={printerIcon}
          ></img>
        </div>
        <SimpleBar className="simple-lastActivity">
          <div id="lastActivityTable" ref={uaRef}>
            <table>
              <thead>
                <tr>
                  <th>{props.t("login.benutzer")}</th>
                  <th>{props.t("kalender.element")}</th>
                  <th>{props.t("kalender.tableTimerList.headerAenderung")}</th>
                  <th>{props.t("dashboard.alterWert")}</th>
                  <th>{props.t("dashboard.neuerWert")}</th>
                  <th>{props.t("allgemein.zeitstempel")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={"key_" + item.id}>
                    <td>{item.userName}</td>
                    <td title={item.elementTyp}>{item.elementName}</td>
                    <td>{item.changeDescription}</td>
                    <td>{item.oldValue}</td>
                    <td>{item.newValue}</td>
                    <td>{convertDate(item.timestamp)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      </div>
    );
  }

  return (
    <div className="pagewrapper-2">
      {loaderShow == true && (
        <div className="loader">
          <ReactLoading
            type={"bars"}
            color={"#2a3042"}
            height={80}
            width={80}
            name={"Loading"}
          />
        </div>
      )}
      {allActivities()}
    </div>
  );
};

export default withNamespaces()(UsersActivity);
