import axios from "axios";
import { withNamespaces } from 'react-i18next';
import SimpleBar from "simplebar-react";

import ReactLoading from 'react-loading';

import React, { useState, useEffect, useRef } from 'react';

const AliveStatus = (props) => {

    let interval
    let jwt = localStorage.getItem('JWT')
    let link = localStorage.getItem('link')

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt;

    const [loaderShow, setLoaderShow] = useState(false);
    const [data, setData] = useState([]);
    const aliveRef = useRef();

    function loadAliveData() {
        axios.get(link + '/api/SystemInfo/CarParkAlive')
            .then((response) => {
                //  console.debug(response);

                var lng = localStorage.getItem("i18nextLng");
                var percFormat = new Intl.NumberFormat(lng, { style: "percent" });
                var numbFormat = new Intl.NumberFormat(lng, { style: "decimal" });
                let responseData = response.data
                for (let i = 0; i < responseData.length; i++) {
                    responseData[i].lastAliveReceivedRabbitMQText = responseData[i].lastAliveReceivedRabbitMQ != null ? new Date(responseData[i].lastAliveReceivedRabbitMQ).toLocaleString() : '';
                    responseData[i].lastAliveReceivedRestText = responseData[i].lastAliveReceivedRest != null ? new Date(responseData[i].lastAliveReceivedRest).toLocaleString() : '';

                    responseData[i].diffRabbitText = responseData[i].diffRabbit != null ? formatTimeSpan(responseData[i].diffRabbit) : '';
                    responseData[i].diffRestText = responseData[i].diffRest != null ? formatTimeSpan(responseData[i].diffRest) : '';

                    responseData[i].winDriveFreeText = '';
                    responseData[i].winDriveFreeTitle = '';
                    responseData[i].winDriveFreeStatusColor = 'gray';
                    if (responseData[i].statusData != null) {
                        if (responseData[i].statusData.licenseValid != null && responseData[i].statusData.licenseValid != true) {
                            responseData[i].statusData.serviceVersionString = 'LICENSE INVALID';
                        }

                        if (responseData[i].statusData.winDriveInfo != null) {
                            var winDriveInfo = responseData[i].statusData.winDriveInfo;
                            var max = winDriveInfo.totalSize;
                            var maxGB = max / 1024.0 / 1024.0 / 1024.0;
                            var free = winDriveInfo.availableFreeSpace;
                            var freeGB = free / 1024.0 / 1024.0 / 1024.0;
                            var freePerc = free / max;
                    
                            responseData[i].winDriveFreeText = percFormat.format(freePerc);
                            responseData[i].winDriveFreeTitle = "Free: " + numbFormat.format(freeGB) + " GB\r\nMax: " + numbFormat.format(maxGB) + " GB";
                            if (freeGB <= 5.0) {
                                responseData[i].winDriveFreeStatusColor = "red";
                            } else if (freeGB <= 20.0) {
                                responseData[i].winDriveFreeStatusColor = "yellow";
                            } else {
                                responseData[i].winDriveFreeStatusColor = "green";
                            }
                        }
                    }
                }

                setData(responseData)
                setLoaderShow(false)
            })
    }
    function formatTimeSpan(ts){
        let tsString = ts.toString();
        return tsString.substring(0, tsString.indexOf(".")) + ' s';
    }

    useEffect(() => {
        setLoaderShow(true)

        loadAliveData()
        interval = setInterval(loadAliveData, 1000 * 10);

    }, []);

    function getStatusColor(saved, errorCounter) {
        if (errorCounter <= 0 && saved === true) {
            return "green";
        } else if (errorCounter > 0 && saved === true) {
            return "yellow";
        } else if (saved === false) {
            return "red";
        } else {
            return "gray";
        }
    }

    function getBoolStatusColor(status) {
        return status === true ? 'green' : 'red';
    }
    
    function aliveStatus() {
        return (
            <div className="left-container-se left-part-alive" >
                <SimpleBar className="simple-alive">
                    <div id="aliveTable" ref={aliveRef}>
                        <table>
                            <thead>
                                <tr>
                                    <th>Site</th>
                                    <th>Parkhaus</th>
                                    <th>Version</th>
                                    <th colSpan={2}>Rabbit</th>
                                    <th colSpan={2}>Rest</th>
                                    <th>General</th>
                                    <th>Database</th>
                                    <th>WinDrive</th>
                                </tr>
                            </thead>
                            <tbody >
                                {data.map((item) => (
                                    <>
                                        <tr>
                                            <td>{item.site}</td>
                                            <td>{item.name}</td>
                                            <td class="version">{item.statusData != null ? item.statusData.serviceVersionString : ''}</td>
                                            <td>{item.lastAliveReceivedRabbitMQText}</td>
                                            <td align="right">{item.diffRabbitText}</td>

                                            <td>{item.lastAliveReceivedRestText}</td>
                                            <td align="right">{item.diffRestText}</td>

                                            <td>
                                                {item.statusData != null &&
                                                    <svg width="60" height="20">
                                                        <circle r="5" cx="10" cy="10" fill={getBoolStatusColor(item.statusData.projectLoaded)}><title>Project</title></circle>
                                                        <circle r="5" cx="30" cy="10" fill={getBoolStatusColor(item.statusData.databaseConnected)}><title>Database</title></circle>
                                                        <circle r="5" cx="50" cy="10" fill={getBoolStatusColor(item.statusData.rabbitConnected)}><title>Rabbit</title></circle>
                                                    </svg>
                                                }
                                            </td>

                                            <td>
                                                {item.statusData != null &&
                                                    <svg width="100" height="20">
                                                        <circle r="5" cx="10" cy="10" fill={getStatusColor(item.statusData.databaseConnectionInfo.elementParametersSaved, item.statusData.databaseConnectionInfo.elementParametersErrorCounter)}><title>Element Parameters ( Error Count: {item.statusData.databaseConnectionInfo.elementParametersErrorCounter})</title></circle>
                                                        <circle r="5" cx="30" cy="10" fill={getStatusColor(item.statusData.databaseConnectionInfo.lotChangeLogSaved, item.statusData.databaseConnectionInfo.lotChangeLogErrorCounter)}><title>Lot Change Log ( Error Count: {item.statusData.databaseConnectionInfo.lotChangeLogErrorCounter})</title></circle>
                                                        <circle r="5" cx="50" cy="10" fill={getStatusColor(item.statusData.databaseConnectionInfo.lotOccupationLogSaved, item.statusData.databaseConnectionInfo.lotOccupationLogErrorCounter)}><title>Lot Occupation Log ( Error Count: {item.statusData.databaseConnectionInfo.lotOccupationLogErrorCounter})</title></circle>
                                                        <circle r="5" cx="70" cy="10" fill={getStatusColor(item.statusData.databaseConnectionInfo.countingUnitChangeLogSaved, item.statusData.databaseConnectionInfo.countingUnitChangeLogErrorCounter)}><title>Counting Unit ChangeLog ( Error Count: {item.statusData.databaseConnectionInfo.countingUnitChangeLogErrorCounter})</title></circle>
                                                        <circle r="5" cx="90" cy="10" fill={getStatusColor(item.statusData.databaseConnectionInfo.areaValuesSaved, item.statusData.databaseConnectionInfo.areaValuesErrorCounter)}><title>Area Values Log ( Error Count: {item.statusData.databaseConnectionInfo.areaValuesErrorCounter})</title></circle>
                                                    </svg>
                                                }
                                            </td>

                                            <td title={item.winDriveFreeTitle}>
                                                {item.statusData != null &&
                                                    <svg width="20" height="20">
                                                        <circle r="5" cx="10" cy="10" fill={item.winDriveFreeStatusColor}></circle>
                                                    </svg>
                                                }
                                                {item.winDriveFreeText}
                                            </td>
                                        </tr>
                                    </>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </SimpleBar>
            </div>
        )
    }

    return (
        <div className="pagewrapper-2">
            {loaderShow == true && (
                <div className="loader">
                    <ReactLoading type={"bars"} color={"#2a3042"} height={80} width={80} name={"Loading"} />
                </div>
            )}
            {aliveStatus()}
        </div>
    )

}

export default withNamespaces()(AliveStatus)

