import axios from "axios";
import { withNamespaces } from "react-i18next";
import SimpleBar from "simplebar-react";

import ReactLoading from "react-loading";

import CsvDownloader from "react-csv-downloader";
import { useReactToPrint } from "react-to-print";

import printerIcon from "../../assets/images/mgsuite/printer-icon.png";
import csvIcon from "../../assets/images/mgsuite/csv-icon.png";

import React, { useState, useEffect, useRef } from "react";

const SpecialSettings = (props) => {
  let jwt = localStorage.getItem("JWT");
  let link = localStorage.getItem("link");

  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

  const [loaderShow, setLoaderShow] = useState(false);
  const [lotType, setLotType] = useState([]);
  const [mode, setMode] = useState([]);
  const [parkingTimeRestrictionEnabled, setParkingTimeRestrictionEnabled] =
    useState([]);
  const [remark, setRemark] = useState([]);
  const [reserved, setReserved] = useState([]);
  const specialsettingsRef = useRef();

  useEffect(() => {
    axios.get(link + "/api/Lot/SpecialSettings").then((response) => {
      setLotType(response.data.LotType);
      setMode(response.data.Mode);
      setParkingTimeRestrictionEnabled(
        response.data.ParkingTimeRestrictionEnabled
      );
      setRemark(response.data.Remark);
      setReserved(response.data.Reserved);
      setLoaderShow(false);
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => specialsettingsRef.current,
  });

  function specialSettings() {
    const combined = [
      ...lotType,
      ...mode,
      ...parkingTimeRestrictionEnabled,
      ...remark,
      ...reserved,
    ];
    const data = combined.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
    data.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div className="left-container-se left-part-specialSettings">
        <div
          className="flex-iac"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <CsvDownloader filename="specialSettings" separator=";" datas={data}>
            <img
              src={csvIcon}
              className="export-img"
              title="csv"
              style={{ marginLeft: "10px" }}
            ></img>
          </CsvDownloader>
          <img
            onClick={handlePrint}
            className="export-img"
            title="print"
            style={{ marginLeft: "10px" }}
            src={printerIcon}
          ></img>
        </div>
        <SimpleBar className="simple-specialSettings">
          <div id="specialSettingsTable" ref={specialsettingsRef}>
            <table>
              <thead>
                <tr>
                  <th>{props.t("lot.lot")}</th>
                  <th>{props.t("allgemein.modus")}</th>
                  <th>{props.t("lot.reserviert")}</th>
                  <th>{props.t("elementeAllgemein.typ")}</th>
                  <th>{props.t("elementeAllgemein.zeitueberwachung")}</th>
                  <th>{props.t("allgemein.bemerkung")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={"key_" + item.id}>
                    <td>{item.name}</td>
                    <td>
                      {item.mode == "0"
                        ? props.t("lot.modes.Automatic")
                        : item.mode == "1"
                        ? props.t("lot.modes.ManualFree")
                        : item.mode == "2"
                        ? props.t("lot.modes.ManualOccupied")
                        : ""}
                    </td>
                    <td>
                      {item.reserved == true
                        ? props.t("allgemein.ja")
                        : props.t("allgemein.nein")}
                    </td>
                    <td>{item.lotType != null ? item.lotType : ""}</td>
                    <td>
                      {item.parkingTimeRestrictionEnabled == true
                        ? props.t("allgemein.ja")
                        : props.t("allgemein.nein")}
                    </td>
                    <td>{item.remark != null ? item.remark : ""}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      </div>
    );
  }

  return (
    <div className="pagewrapper-2">
      {loaderShow == true && (
        <div className="loader">
          <ReactLoading
            type={"bars"}
            color={"#2a3042"}
            height={80}
            width={80}
            name={"Loading"}
          />
        </div>
      )}
      {specialSettings()}
    </div>
  );
};

export default withNamespaces()(SpecialSettings);
