import axios from "axios";
import { withNamespaces } from "react-i18next";
import SimpleBar from "simplebar-react";

import ReactLoading from "react-loading";

import CsvDownloader from "react-csv-downloader";
import { useReactToPrint } from "react-to-print";

import printerIcon from "../../assets/images/mgsuite/printer-icon.png";
import csvIcon from "../../assets/images/mgsuite/csv-icon.png";

import React, { useState, useEffect, useRef } from "react";

const Timeouts = (props) => {
  let jwt = localStorage.getItem("JWT");
  let link = localStorage.getItem("link");

  axios.defaults.headers.common["Authorization"] = "Bearer " + jwt;

  const [loaderShow, setLoaderShow] = useState(false);
  const [data, setData] = useState([]);
  const toRef = useRef();

  const columns = [
    {
      id: "name",
      displayName: props.t("lot.lot"),
    },
    {
      id: "lastTimeLotGotOccupied",
      displayName: props.t("lot.parkBeginn"),
    },
    {
      id: "duration",
      displayName: props.t("lot.parkDauer"),
    },
  ];

  useEffect(() => {
    axios.get(link + "/api/Lot/Exceeded").then((response) => {
      const data = response.data.map((item) => ({
        name: item.name,
        lastTimeLotGotOccupied: convertDate(item.lastTimeLotGotOccupied),
        duration: getParkingDuration(item.lastTimeLotGotOccupied),
      }));

      setData(data);
      setLoaderShow(false);
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => toRef.current,
  });

  function convertDate(date) {
    if (date == null || date === undefined) {
      return props.t("allgemein.unbekannt");
    }

    const currentDate = new Date(date);
    const currentDayOfMonth = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    let minutes = currentDate.getMinutes();
    let hours = currentDate.getHours();
    let seconds = currentDate.getSeconds();
    let month;
    let day;

    if (currentDayOfMonth < 10) {
      day = "0" + currentDayOfMonth;
    } else {
      day = currentDayOfMonth;
    }

    if (currentMonth < 10) {
      month = "0" + currentMonth;
    } else {
      month = currentMonth;
    }

    if (hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    const dateTimeString =
      day +
      "." +
      month +
      "." +
      currentYear +
      " " +
      hours +
      ":" +
      minutes +
      ":" +
      seconds;

    return dateTimeString;
  }

  function getParkingDuration(gotOccupied) {
    let parkingDuration;

    let date = new Date(gotOccupied);
    let date2 = new Date();

    let diffMS = Math.abs(date2 - date);
    let days = Math.floor(diffMS / (24 * 60 * 60 * 1000));
    let daysms = diffMS % (24 * 60 * 60 * 1000);
    let hours = Math.floor(daysms / (60 * 60 * 1000));
    let hoursms = diffMS % (60 * 60 * 1000);
    let minutes = Math.floor(hoursms / (60 * 1000));

    let d = ("0" + days).slice(-2) + "d ";
    if (days < 1) {
      d = "";
    }
    if (days > 99) {
      d = days + "d ";
    }
    let h = ("0" + hours).slice(-2) + "h ";
    let m = ("0" + minutes).slice(-2) + "m";

    parkingDuration = d + h + m;

    return parkingDuration;
  }

  function timeouts() {
    return (
      <div className="left-container-se left-part-timeouts">
        <div
          className="flex-iac"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <CsvDownloader
            filename="timeouts"
            separator=";"
            columns={columns}
            datas={data}
          >
            <img
              src={csvIcon}
              className="export-img"
              title="csv"
              style={{ marginLeft: "10px" }}
            ></img>
          </CsvDownloader>
          <img
            onClick={handlePrint}
            className="export-img"
            title="print"
            style={{ marginLeft: "10px" }}
            src={printerIcon}
          ></img>
        </div>
        <SimpleBar className="simple-timeouts">
          <div id="timeoutsTable" ref={toRef}>
            <table>
              <thead>
                <tr>
                  <th>{props.t("lot.lot")}</th>
                  <th>{props.t("lot.parkBeginn")}</th>
                  <th>{props.t("lot.parkDauer")}</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => (
                  <tr key={"key_" + item.id}>
                    <td>{item.name}</td>
                    <td>{item.lastTimeLotGotOccupied}</td>
                    <td>{item.duration}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      </div>
    );
  }

  return (
    <div className="pagewrapper-2">
      {loaderShow == true && (
        <div className="loader">
          <ReactLoading
            type={"bars"}
            color={"#2a3042"}
            height={80}
            width={80}
            name={"Loading"}
          />
        </div>
      )}
      {timeouts()}
    </div>
  );
};

export default withNamespaces()(Timeouts);
